<template>
  <NavigationBar
    v-if="showNavbar"
    @scroll-to-home="scrollToHome"
    @scroll-to-about="scrollToAbout"
    @scroll-to-services="scrollToServices"
    @scroll-to-contact="scrollToContact"
  />

  <section
    class="hero-section d-flex justify-content-center align-items-center"
    id="home"
  >
    <div class="">
      <div class="furnace-logo text-center">
        <img
          src="@/assets/img/furnacetwo.png"
          alt=""
          width="500"
          class="img-fluid"
        />
      </div>
      <div class="mt-2 text-center">
        <p class="sub-title">
          <span class="typed-text text-white">{{ currentText }}</span>
          <span class="blinking-cursor text-white">|</span>
        </p>
      </div>
      <div class="cta-btn d-flex justify-content-center align-items-center">
        <ArrowButton class="mt-5" @click="scrollToNextSection" />
      </div>
    </div>
  </section>

  <section class="about-section" id="about-section">
    <div class="p-5 container-fluid">
      <div class="row">
        <div class="col-12 col-lg-7">
          <Carousel :items-to-show="1" :wrap-around="true" :autoplay="3000">
            <Slide v-for="aboutSlide in aboutSlides" :key="aboutSlide.id">
              <div class="carousel__item2">
                <div class="about-slider">
                  <h1>{{ aboutSlide.header }}</h1>

                  <div class="container">
                    <p class="about-text">{{ aboutSlide.content }}</p>
                  </div>
                </div>
              </div>
            </Slide>

            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>

        <div class="col-12 col-lg-5 product-container">
          <img
            src="@/assets/img/product.png"
            alt=""
            width="700"
            class="img-fluid"
          />
        </div>
      </div>
    </div>

    <div class="p-5 container-fluid" id="services">
      <div class="">
        <div class="row">
          <div class="col-12 col-md-4">
            <div
              class="card-title d-flex justify-content-center align-items-center "
            >
              <img
                src="@/assets/img/tank5.jpg"
                alt=""
                width="350"
                class="img-fluid rounded-circle mt-3"
              />
            </div>
            <div class="card-body">
              <h4 class="text-center mt-3">PETROLEUM PRODUCT SUPPLY</h4>
              <p class="text-center service-text">
                Ensuring a seamless and reliable supply of furnace oil, diesel,
                and petrol. Our strategic approach covers efficient logistics,
                quality assurance and personalized solutions tailored to meet
                your industrial fuel requirements.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div
              class="card-title d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/img/cont.png"
                alt=""
                width="350"
                class="img-fluid mt-2"
              />
            </div>
            <div class="card-body">
              <h4 class="text-center mt-3">LOGISTICS</h4>
              <p class="text-center service-text">
                We excel in the logistics of petroleum products, including
                efficient transportation and timely deliveries. Our logistics
                capabilities extend to managing inventory, ensuring product
                integrity and providing tailored solutions for your petroleum
                distribution needs.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div
              class="card-title d-flex justify-content-center align-items-center"
            >
              <img
                src="@/assets/img/customercare.jpeg"
                alt=""
                width="350"
                class="img-fluid rounded-circle mt-3"
              />
            </div>
            <div class="card-body">
              <h4 class="text-center mt-3">CLIENT SUPPORT</h4>
              <p class="text-center service-text">
                Our commitment goes beyond supply and logistics. We offer
                personalized client support, ensuring a seamless experience.
                From order processing to addressing queries, our team is
                dedicated to meeting needs with.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="counter-container container-fluid mt-5" id="counter-section">
      <div class="container">
        <div class="row p-3">
          <div class="col-4 col-md-4 text-white mt-3 center-items">
            <div class="text-center counter-item">
              <Counter ref="counterComponent1" :dynamicEndAmount="4" />
              <p>Years Of Experience</p>
            </div>
          </div>

          <div class="col-4 col-md-4 text-white mt-3 center-items">
            <div class="text-center counter-item">
              <Counter ref="counterComponent2" :dynamicEndAmount="5" />
              <p>Petroleum Products</p>
            </div>
          </div>
          <div class="col-4 col-md-4 text-white mt-3 center-items">
            <div class="text-center counter-item">
              <Counter ref="counterComponent4" :dynamicEndAmount="100" />
              <p>Customers Served</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid client-section bg-dark">
      <div class="row">
        <div class="col-12">
          <div class="container">
            <h2 class="text-center mt-5 text-white">Clients</h2>
          </div>
        </div>

        <div class="col-12">
          <Carousel
            :items-to-show="3"
            :wrap-around="true"
            :autoplay="3000"
            class=""
          >
            <Slide v-for="award in awards" :key="award.id" class="p-1">
              <div class="carousel__item mt-2">

                <img
                  :src="award.url"
                  alt=""
                  class="img-fluid client-logos"
                  width="100"
                />
                <p class="text-white  client-logos-text text-bold mt-3">{{ award.text }}</p>
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>

      <div class="footer-section mt-3" id="contact">
        <footer class="text-white py-4">
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <h3 class="text-center">Contact</h3>
                <div class="row">
                  <div class="col-12 center-items">
                    <p>info@furnace-oil-merchants </p>
                  </div>
                  <div class="col-12 center-items">
                    <p>+254 729 254 827</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <h3 class="text-center">Social Media</h3>
                <ul class="list-unstyled center-items">
                  <li class="list-inline-item">
                    <a href="#"
                      ><font-awesome-icon
                        icon="fa-brands fa-facebook"
                        class="icon p-3 fa-2x"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#"
                      ><font-awesome-icon
                        icon="fa-brands fa-instagram"
                        class="icon p-3 fa-2x"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#"
                      ><font-awesome-icon
                        icon="fa-brands fa-twitter"
                        class="icon p-3 fa-2x"
                    /></a>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <h3 class="text-center">Location</h3>
                <p class="text-center">
                  Depot Plaza - Lunga lunga rd Nairobi, Kenya
                </p>

               
                
              </div>
            </div>
          </div>

           <div class="maps container">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.107529634948!2d36.84027261202928!3d-1.3091433794778853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11786abbf8a7%3A0x8044556b50b3bdfd!2sLUNGA%20PLAZA!5e0!3m2!1sen!2ske!4v1706007772154!5m2!1sen!2ske"
                    class="w-100"
                    height="350"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>

          <div class="center-items mt-5">
            <p>
              © {{ currentYear }} Copyright:
              <a class="text-white"> Furnace Oil Merchants Ltd</a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import ArrowButton from "@/components/ArrowButton.vue";
import Counter from "@/components/CounterElement.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

export default {
  components: {
    NavigationBar,
    ArrowButton,
    Carousel,
    Pagination,
    Slide,
    Navigation,
    Counter,
  },

  data() {
    return {
      texts: [
        "Empowering Excellence in Petroleum Solutions",
        "Fueling Success & Maximizing Efficiency!",
      ],
      currentIndex: 0,
      currentText: "",
      cursorBlink: true,
      showNavbar: false,
      aboutSlides: [
        {
          id: "1",
          header: "HISTORY",
          content:
            "FURNACE MERCHANTS LTD, established in 2021, is a dynamic petroleum products supplier in Kenya. From small-scale beginnings we've swiftly grown to meet industry demands. Sourcing from reputable companies like Bakri and Total Kenya, we offer reasonable credit facilities (21, 30, and 45 days). Our stable capital investment enables us to move large product volumes monthly and our esteemed industry partnerships speak to our reliability. Trust us for efficient, timely and trustworthy petroleum supply.",
        },

        {
          id: "2",
          header: "OUR OBJECTIVES",
          content:
            "Exceeding client expectations through exceptional service, flexibility and value. Our expert team ensures optimal system functionality, drawing on their unique blend of technical expertise and hands-on experience. As petroleum product specialists, we guide clients from selection to product suitability, providing advice on the best type and quality that meets their needs.",
        },
        {
          id: "3",
          header: "DELIVERY",
          content:
            "Our robust fleet of delivery trucks, featuring three 28,000-liter and two 16,000-liter capacity vehicles ensures an uninterrupted and ample fuel supply around the clock. With just 24 hours' notice, we stand ready to execute swift and reliable deliveries anywhere across the country. Your fuel needs are our priority and our efficient fleet guarantees timely and consistent service.",
        },
      ],

      awards: [
        {
          id: "1",
          url: require("@/assets/img/united.png"),
          text: "Regal Pharmaceuticals",
        },
        {
          id: "2",
          url: require("@/assets/img/millars.jpg"),
          text: "United MIllars",
        },
        {
          id: "3",
          url: require("@/assets/img/dpl.png"),
          text: "Dothia Packaging",
        },
      ],
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {
    this.typeText();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleCounterScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        this.showNavbar = true;
      } else {
        this.showNavbar = false;
      }
    },

    scrollToHome() {
      setTimeout(() => {
        const section = document.getElementById("home");
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Adjust the delay as needed
    },

    scrollToAbout() {
      setTimeout(() => {
        const section = document.getElementById("about-section");
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Adjust the delay as needed
    },

    scrollToServices() {
      setTimeout(() => {
        const section = document.getElementById("services");
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
    scrollToContact() {
      setTimeout(() => {
        const section = document.getElementById("contact");
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
    typeText() {
      const text = this.texts[this.currentIndex];
      const delay = 60; // Typing speed in milliseconds

      let charIndex = 0;
      const interval = setInterval(() => {
        if (charIndex < text.length) {
          this.currentText += text[charIndex];
          charIndex++;
        } else {
          clearInterval(interval);
          setTimeout(this.eraseText, 1000); // Wait for 1 second before erasing
        }
      }, delay);
    },
    eraseText() {
      const text = this.texts[this.currentIndex];
      const delay = 30; // Erasing speed in milliseconds

      let charIndex = text.length;
      const interval = setInterval(() => {
        if (charIndex > 0) {
          this.currentText = text.slice(0, charIndex - 1);
          charIndex--;
        } else {
          clearInterval(interval);
          this.cursorBlink = false;
          setTimeout(this.nextText, 500); // Wait for 1 second before moving to the next text
        }
      }, delay);
    },
    nextText() {
      this.currentIndex = (this.currentIndex + 1) % this.texts.length;
      this.cursorBlink = true;
      setTimeout(this.typeText, 500); // Wait for 0.5 seconds before typing the next text
    },

    scrollToNextSection() {
      const section = document.getElementById("about-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },

    handleCounterScroll() {
      const counterSection = document.getElementById("counter-section");
      const counterComponent1 = this.$refs.counterComponent1;
      const counterComponent2 = this.$refs.counterComponent2;
      const counterComponent3 = this.$refs.counterComponent3;
      const counterComponent4 = this.$refs.counterComponent4;

      if (counterSection) {
        if (counterComponent1 && this.isElementInViewport(counterSection)) {
          counterComponent1.start();
        }
        if (counterComponent2 && this.isElementInViewport(counterSection)) {
          counterComponent2.start();
        }
        if (counterComponent3 && this.isElementInViewport(counterSection)) {
          counterComponent3.start();
        }
        if (counterComponent4 && this.isElementInViewport(counterSection)) {
          counterComponent4.start();
        }
      }
    },

    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom >= 0
      );
    },
  },
};
</script>

<style></style>

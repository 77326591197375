<template>
  <vue3-autocounter
    ref="counter"
    :startAmount="0"
    :endAmount="dynamicEndAmount"
    :duration="1"
    suffix=" +"
    separator=","
    :autoinit="false"
    class="vue-counter"

  />

</template>

<script>

export default {

    props: {
    dynamicEndAmount: Number, 
  },
    methods:{
            start() {
    this.$refs.counter.start();
  },
    }

}
</script>


<template>
  <section id="app">
    <div class="background-mask">
      <router-view />
    </div>
  </section>
</template>

<style>
</style>

<template>
  <nav
    class="navbar navbar-expand-md fixed-top"
    :class="{ 'navbar-bg': showNavbarBg }"
  >
    <div class="container">
      <a href="#" class="navbar-brand me-auto">
        <img
          src="@/assets/img/furnacetwo.png"
          alt="Harold Logo"
          height="70"
          class=" "
        />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleMenu"
      >
        <font-awesome-icon
          icon="fa-solid fa-bars"
          class="text-white"
          v-if="!closed"
        />
        <font-awesome-icon
          icon="fa-solid fa-x"
          class="text-white"
          v-if="closed"
        />
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto horizontal-list">
          <li class="nav-item px-1">
            <a
              href="#"
              class="nav-link text-white text-black"
              @click="handleScrollToHome"
              >Home</a
            >
          </li>
          <li class="nav-item px-2">
            <a
              href="#"
              class="nav-link text-white text-black"
              @click="handleScrollToAbout"
              >About</a
            >
          </li>
          <li class="nav-item px-1">
            <a
              href="#"
              class="nav-link text-white text-black"
              @click="handleScrollToServices"
              >What We Do</a
            >
          </li>
          <li class="nav-item px-1">
            <a
              href="#"
              class="nav-link text-white text-black"
              @click="handleScrollToContact"
              >Contact</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      closed: false,
      showNavbarBg: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleMenu() {
      this.closed = !this.closed;
    },

    handleScroll() {
      // Calculate the scroll position
      const scrollPosition = window.scrollY;

      // Determine when to apply the .navbar class
      this.showNavbarBg = scrollPosition > 550;
    },

    handleScrollToHome() {
      this.$emit("scroll-to-home");
    },

    handleScrollToAbout() {
      this.$emit("scroll-to-about");
    
    },
    handleScrollToContact() {
      this.$emit("scroll-to-contact");
      
    },
    handleScrollToServices() {
      this.$emit("scroll-to-services");
    },
  },
};
</script>

<style>
</style>
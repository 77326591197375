import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import '@/assets/main.css'
import Vue3Autocounter from 'vue3-autocounter';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faX } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import {MotionPlugin} from '@vueuse/motion';

library.add(faBars,faX,faTwitter,faFacebook,faInstagram)

createApp(App).component('vue3-autocounter', Vue3Autocounter).use(MotionPlugin).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router).mount('#app')
